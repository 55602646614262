
.toppa{
    width: 100%;
    height: 70px;
    background-image: url(./../media/img/red.jpeg);
    /* border: 1px solid #ff0; */

    /* background-position: 50% 0; */
    padding: 0 10px 0 0;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 10px;
    height: 70px;
    min-height: 70px;
}

.sec1{
    width: 11.5vw;
    height: inherit !important;
    background-color: #000;

    grid-column: 1/span 1;
    background-color: #000;
}

.logg{
    position: absolute;
    height: 150px;
    height: 130px;
    top: -10px;
    z-index: 1000;
    padding-top: 0;
    padding-bottom: 2%;
    margin-left: 50px;
}

.sec2{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.acrobat{
    height: 60px;
}

.navBa_r_lkjhgyuikjnbhn{
    width: 100%;
    height: 65px;
    border: 1px solid #eaeaae;
    box-shadow: 2px 3px 3px #eaeaae;
    box-shadow: 0 15px 10px -15px #111;
    background-color: #fff;
  }

  .navvv_btn_container{
    width: inherit !important;
    height: inherit !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* border: 1px solid #000; */
  }

  .navvv_btn_container > ul {
    list-style: none;
    margin-top: 16px;
    margin-right: 24px;
  }

  .navvv_btn_container > ul > .llllist{
    display: inline !important;
    padding: 7px;
  }

  ._navvbtnnn{
    font-family: helvetica,verdana,tahoma,arial;
    font-size: 15px;
    color: #333;
    text-transform: capitalize;
    background-color: #cac8bf;
    border: 1px solid #bebcaf;
    padding: 13px 15px;
    display: inline;
    cursor: pointer;
  }

  ._navvbtnnn:hover{
    background: #ddd;
  }
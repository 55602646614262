

.centra____l_kjhgftyjkK{
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.form_wrapper___{
    width: 500px;
    height: 550px;
    height: auto;
    /* border: 1px solid #333; */
    z-index: 1000;
    background: #fff;
    box-shadow: 3px 4px 5px #eee;
}

.securrr{
    /* max-width: 500px; */
    /* width: 100%; */
    margin-bottom: 20px;
    width: 60px;
}


.clasfrm {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    margin: 10px 2px;
    width: auto;
}

.clasfrm > span {
    padding: 10px;
    line-height: 1.5;
    font-size: 15px;
    border: 1px solid #000;
    border-right: 0;
}

[class^="ri-"], [class*=" ri-"] {
    font-family: 'remixicon' !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.clasfrm > input {
    width: 410px;
    padding: 10px;
    line-height: 1.5;
    font-size: 15.5px;
    border: 1px solid #000;
    outline: none !important;
    transition: all .2s linear;
}

.clasfrm > input:focus{
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 11px;
}

#view{
    width: 200px;
    padding: 10px;
    border-radius: 0;
    border: 1px solid #e4e4e4;
    font-size: 15px;
    font-family: Verdana;
    /* background: #b74446; */
    background: rgb(38, 38, 211);
    color: #fff;
    cursor: pointer;
    outline: none;
    border: 1px solid rgb(38, 38, 211);
    border-radius: 25px;
    font-weight: bold;
    font-family: sans-serif;
    /* margin-right: 3cm; */
    /* margin-left: -9em; */
}